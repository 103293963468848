<template>
  <div class="container">
    <transition class="mobile" name="slide-right">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/collection-image.png">
    </transition>

    <transition name="slide-left">
      <div v-if="isVisible" class="summary">
        <h1 class="header">Collection</h1>
        <p class="text"> At our core, we firmly stand for the principle of exclusivity. 
            Our commitment is to create a space where each participant feels a sense of ownership and connection with their avatar. 
            Therefore, the entire collection will consist of <span style="color: #dc2f02">1/1</span> hand-drawn rendered characters. No duplicates.
        </p>
      </div>
    </transition>
    <transition class="desktop" name="slide-right">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/collection-image.png">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CollectionSection',
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  },
}
</script>

<style scoped>

.slide-left-enter-active, .slide-right-enter-active {
  transition: all 1s ease;
}

.slide-left-enter-from, .slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-left-enter-to, .slide-right-enter-to {
  transform: translateX(0);
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 2rem;
    border-top: 3px solid black;
    overflow: hidden;
    background-color: #1c1718;
    max-height: 20vh;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    max-height: 600px !important;
    padding: 8rem 2rem 10rem 2rem !important;
  }
  .image {
    margin-top: 10px;
    max-width: 100% !important;
    margin-bottom: 0px !important;
  }
  .text {
    font-size: 24px !important;
  }
  .desktop{
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

@media (min-aspect-ratio: 2/1) {
  .image {
    max-width: 480px !important;
  }
}

.image{
    max-width: 23vw;
    height: auto;
    object-fit: contain;
    padding: 10px;
    margin: 40px;
}

.summary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    background-color: #f48c06;
    border-radius: 15px;
    max-width: 800px;
}

.header {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: 900;
    font-size: 40px;
}
.text{
    color: white;
    font-size: 30px;
    text-align: left;
}
</style>