<template>
  <div class="main" id="landing-section">
    <transition name="fade">
      <img v-if="showImages" class="logo" alt="logo" src="../assets/logo.png">
    </transition>
    <transition name="fade">
      <img v-if="showImages" class="landing-main" alt="landing-main" src="../assets/landing-main.gif">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LandingScreen',
  data() {
    return {
      showImages: false,
    };
  },
  mounted() {
    this.showImages = true;
  },
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: white;
  padding: 0 5rem;
  flex-wrap: wrap;
}

.logo, .landing-main {
  max-width: 100%; /* Do not exceed the width of the container */
  max-height: 100%; /* Do not exceed the height of the container */
  object-fit: contain; /* Maintain aspect ratio */
}

@media (max-width: 1650px) {
  .landing-main {
    display: none;
  }
}
/* .main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: white;
  padding: 0 5rem;
  flex-wrap: wrap;
}

.logo {
  max-width: 40%;
  height: auto;
  object-fit: contain;
}

.landing-main {
  max-width: 40%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .logo {
    max-width: 80%; /* Increase this value to make the logo bigger */
  /* }

  .landing-main {
    display: none !important;
  }
}

@media (max-width: 1920px) {
  .logo {
    max-width: 50%;
    height: auto;
    object-fit: contain;
  }

  .landing-main {
    max-width: 40%;
    height: auto;
    object-fit: contain;
  }
} */ 

</style>