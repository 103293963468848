<template>
  <div class="container">
    <transition name="slide-left">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/wenwhitelist.png">
    </transition>
    <transition name="slide-right">
      <div class="summary" v-if="isVisible">
          <h1 class="header">Wen Whitelist?</h1>
          <p class="text">
              Invitations only. Participants seeking whitelist access should actively engage with the community, follow project updates, and build relationships within the project's ecosystem to increase their chances of receiving an invitation.
              Got what it takes? See you at the Tavern.
          </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'WenWhitelist',
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  },
}
</script>

<style scoped>
.slide-left-enter-active, .slide-right-enter-active {
  transition: all 1s ease;
}

.slide-left-enter-from, .slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-left-enter-to, .slide-right-enter-to {
  transform: translateX(0);
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 2rem;
    border-top: 3px solid black;
    overflow: hidden;
    background-color: white;
    max-height: 20vh;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    max-height: 650px !important;
    padding: 6rem 2rem !important;
  }
  .text {
    font-size: 24px !important;
  }
  .image {
    max-width: 100% !important;
  }
}

@media (min-aspect-ratio: 2/1) {
  .image {
    max-width: 600px !important;
  }
}

.image{
    max-width: 25%;
    height: auto;
    object-fit: contain;
}

.summary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0 2rem;
    background-color: rgb(121, 119, 117);
    border-radius: 15px;
    max-width: 800px;
}

.header {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: 900;
    font-size: 40px;
}
.text{
    color: white;
    font-size: 30px;
    text-align: left;
}
</style>