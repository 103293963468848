<template>
  <div class="container" ref="container">
    <transition name="fade">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/blacksword_edited.png">
    </transition>
    <transition name="slide-right">
      <div v-if="isVisible" class="summary">
        <h1 class="header">About Us</h1>
          <p class="text">
              Based in Los Angeles, "FableX" is a captivating fantasy-art inspired NFT (Non-Fungible Token) project that immerses users in a rich and expansive realm.
          </p>
          <p class="text">
              Set in the magical world of "Loom", filled with mystical creatures, ancient artifacts, and unexplored landscapes. Through community-driven events and collaborative storytelling, users have the power to shape the destiny of Loom, leaving their mark on its history for generations to come.
          </p>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'AboutUs',
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        this.isVisible = entry.isIntersecting;
      },
      {
        threshold: 0.1, // adjust this value to control when the callback is called
      }
    );

    observer.observe(this.$refs.container);
  },
}
</script>

<style scoped>

.slide-right-enter-active, .slide-right-leave-active {
  transition: all 2s ease;
}

.slide-right-enter-from, .slide-right-leave-to {
  transform: translateX(100%);
}

.slide-right-enter-to, .slide-right-leave-from {
  transform: translateX(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}


.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 2rem;
    overflow: hidden;
    max-height: 30vh;
    border-top: 3px solid black;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    max-height: 650px !important;
  }
  .text {
    font-size: 24px !important;
  }
  .image {
    width: 100% !important;
  }
}

@media (min-aspect-ratio: 2/1) {
  .image {
    max-width: 950px !important;
  }
}

.image{
    width: 38vw;
    height: auto;
    object-fit: contain;
}

.summary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0 2rem;
    background-color: #01030daf;
    border-radius: 15px;
    max-width: 800px;
}

.header {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: 900;
    font-size: 40px;
}
.text{
    color: white;
    font-size: 30px;
    text-align: left;
}
</style>