<template>
  <div class="container">
    <transition name="slide-left">
      <div class="summary" v-if="isVisible">
          <h1 class="header">Introducing Arcs</h1>
          <p class="text">
            Avatars are divided into three tiers, known as "arcs". These arcs serve as milestones in the avatar's journey, signifying character growth and unlocking new visual aesthetics. 
            With each arc, an avatar will grow stronger and more visually striking.
          </p>
      </div>
    </transition>
    <transition name="slide-right">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/arcs.png">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'IntroducingArcs',
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  },
}
</script>

<style scoped>
.slide-left-enter-active, .slide-right-enter-active {
  transition: all 1s ease;
}

.slide-left-enter-from, .slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-left-enter-to, .slide-right-enter-to {
  transform: translateX(0);
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 2rem;
    border-top: 3px solid black;
    overflow: hidden;
    background-color: #1c1718;
    max-height: 20vh;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    max-height: 80vh !important;
  }
  .image {
    margin-top: 50px;
    max-width: 80% !important;
  }
  .text {
    font-size: 28px !important;
  }
}

@media (min-aspect-ratio: 2/1) {
  .image {
    max-width: 750px !important;
  }
}

.image{
    max-width: 25vw;
    height: auto;
    object-fit: contain;
    padding: 10px;
    margin: 40px;
}

.summary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0 2rem;
    background-color: #f48c06;
    border-radius: 15px;
    max-width: 800px;
}

.header {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: 900;
    font-size: 40px;
}
.text{
    color: white;
    font-size: 30px;
    text-align: left;
}
</style>