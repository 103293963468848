<template>
  <TopNav/>
  <audio src="/public/assets/fablex.mp3" autoplay loop></audio>
  <div id="app">
    <LandingScreen />
    <AboutUs />
    <CollectionSection />
    <CommunitySection />
    <IntroducingArcs style="display: none !important" />
    <WenWhitelist />
    <AudioPlayer />
  </div>
  <BottomFooter />
</template>

<script>
import TopNav from "./components/TopNav.vue";
import LandingScreen from "./components/LandingScreen.vue";
import AboutUs from "./components/AboutUs.vue";
import CollectionSection from "./components/CollectionSection.vue";
import CommunitySection from "./components/CommunitySection.vue";
import IntroducingArcs from "./components/IntroducingArcs.vue";
import WenWhitelist from "./components/WenWhitelist.vue";
import AudioPlayer from "./components/AudioPlayer.vue";
import BottomFooter from "./components/BottomFooter.vue";


export default {
  name: 'FableX',
  components: {
    TopNav,
    LandingScreen,
    AboutUs,
    CollectionSection,
    CommunitySection,
    IntroducingArcs,
    WenWhitelist,
    AudioPlayer,
    BottomFooter
  }
}
</script>

<style>
body {
  margin: 0;
  background: url('../src/assets/fable-banner-main.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#app {
  font-family: 'vt323', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: 'vt323', Helvetica, Arial, sans-serif;
  src: url("./assets/fonts/Vt323.ttf");
}
</style>
