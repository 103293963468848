<template>
  <div class="nav">
    <a href="#landing-section" class="nav-link">Home</a>
    <a href="https://twitter.com/FableXOfficial" class="nav-link" target="_blank">
      <img src="../assets/X.png" alt="x" class="x-link"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
}
</script>

<style scoped>
.nav {
  position: sticky;
  top: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center; /* Add this line */
  padding: 20px;
  gap: 20px;
  z-index: 1000;
}

.nav-link {
  color: orange;
  margin: 0 10px;
  text-decoration: none;
  font-size: 20px;
}

.x-link {
  height: 20px;
  width: 20px;
}
</style>