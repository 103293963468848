<template>
  <div class="audio-player">
    <audio ref="audioRef" :src="audioSrc" @ended="onEnd" @timeupdate="onTimeUpdate"></audio>
    <button class="audio-button" @click="togglePlay">
      <i v-if="isPlaying" class="fas fa-pause"></i>
      <i v-else class="fa-solid fa-play"></i>
    </button>
    <img class="logo" src="../assets/logo.png" width="60px"/>
    <div class="audio-bars" v-if="isPlaying">
      <div class="audio-bar" v-for="i in 5" :key="i" :style="{ animationDelay: (Math.random() * 0.5) + 's' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audioSrc: '/assets/fablex.mp3',
      isPlaying: false,
      volume: 0.25,
    };
  },
  mounted() {
    this.$refs.audioRef.volume = this.volume; // set the volume of the audio element
  },
  methods: {
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audioRef.pause();
      } else {
        this.$refs.audioRef.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    onEnd() {
      this.isPlaying = false;
    },
    changeVolume() {
      this.$refs.audioRef.volume = this.volume;
    },
  },
};
</script>

<style scoped>

.logo {
  max-width: 100%;
  height: auto;
}

.audio-player {
    position: fixed;
    bottom: 4rem;
    left: 0;
    background: #fff;
    padding: 1px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 10px;
}

.audio-button {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-bars {
    display: flex;
    justify-content: space-between;
    width: 50px;
    height: 20px;
    margin-left: 10px;
}

.audio-bar {
    width: 2px;
    height: 100%;
    background: #000;
    animation: pulse 1s infinite ease-in-out;
    transform-origin: bottom;
}

@keyframes pulse {
    0%, 100% { transform: scaleY(1); }
    50% { transform: scaleY(0.5); }
}
</style>