<template>
  <div class="nav">
    Coming soon
  </div>
</template>

<script>
export default {
  name: 'BottomFooter',
}
</script>

<style scoped>
.nav {
  position: sticky;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center; /* Add this line */
  padding: 20px;
  gap: 20px;
  z-index: 1000;
  color: orange;
  font-size: 20px;
}
</style>