<template>
  <div class="container">
    <transition name="slide-left">
      <img v-if="isVisible" class="image" alt="logo" src="../assets/prince.png">
    </transition>
    <transition name="slide-right">
        <div class="summary" v-if="isVisible">
            <h1 class="header">Community</h1>
            <p class="text">
                NFT (Non-Fungible Token) communities play a pivotal role in the rapidly evolving landscape of digital assets and blockchain technology. 
                Our platform recognizes the diverse and vibrant nature of an NFT ecosystem, and we pledge to uphold principles of transparency, inclusivity, and ethical conduct.
            </p>
        </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CommunitySection',
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  },
}
</script>

<style scoped>
.slide-left-enter-active, .slide-right-enter-active {
  transition: all 1s ease;
}

.slide-left-enter-from, .slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-left-enter-to, .slide-right-enter-to {
  transform: translateX(0);
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 2rem;
    border-top: 3px solid black;
    overflow: hidden;
    background-color: #1c1718;
    max-height: 20vh;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    max-height: 650px !important;
    padding: 4rem 2rem 8rem 2rem !important;
  }
  .image {
    margin-bottom: 10px !important;
    max-width: 90% !important;
  }
  .text {
    font-size: 24px !important;
  }
}

@media (min-aspect-ratio: 2/1) {
  .image {
    max-width: 480px !important;
  }
}

.image{
    max-width: 23vw;
    height: auto;
    object-fit: contain;
    padding: 10px;
    margin: 40px;
}

.summary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    background-color: #f48c06;
    border-radius: 15px;
    max-width: 800px;
}

.header {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: 900;
    font-size: 40px;
}
.text{
    color: white;
    font-size: 30px;
    text-align: left;
}
</style>